import React, { useEffect, useState } from "react"
import { useCallback } from 'react'
import './css/Home.css';

const Todos: React.FC<{}> = () => {

  const [drives, setDrives] = useState<any[]>([])
  /*
  const fetchData = async () => {
    const response = await fetch("https://pwa.magpiegrove.net/api/logbook/current.php")
    //const response = await fetch("http://localhost/reactjs/pwa-server-php/logbook/read.php")
    const data = await response.json()
    setTodos(data)
  }
   */
  const fetchData = useCallback(async () => {
    try {
      await fetch("https://pwa.magpiegrove.net/api/logbook/current.php", {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          setDrives(data)
          console.log("data: " + JSON.stringify(data));
        })
    } catch (error) {
      //console.log(error.message)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  let header =
    <div className="current-drive">
      <div>Drive:</div><div>Start:</div><div>From:</div>
      <div><hr/></div><div><hr/></div><div><hr/></div>
    </div>;
  let jsx = drives.map(
     (element, index) => (
       <div key={index} className="current-drive">
         <div><a href={"/update-log/" + element.id}>{element.drive_date}</a></div>
         <div>{element.start_time}</div><div>{element.start_loc}</div>
       </div>
      )
  );
  let listButton =
    <div className="list-button">
      <a href="/drive-list"><button className="btn btn-standard">List All Drives</button></a>
    </div>;

  return (
    <>
      <div className="container">
        <hr/>
        <div className="current-result">
          {header}
          {jsx}
        </div>
        <div className="spacer" />
        <div>
          {listButton}
        </div>
        <div className="spacer" />
      </div>
    </>
 )
};

export default Todos;
