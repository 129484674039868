import React, { useState, useEffect } from 'react';
import './css/ViewDrive.css';

export default function CarCard(
  car: any
) {

  const id = car.car;
  //console.log('id: ' + id);
  //console.log('carid: ' + JSON.stringify(car));
  const [driver, setDriver] = useState<any[]>([])
    const fetchData = async () => {
      const response = await fetch(`https://pwa.magpiegrove.net/api/car/read.php?id=${id}`)
      //const response = await fetch("http://localhost/reactjs/pwa-server-php/logbook/read.php")
      const data = await response.json()
      setDriver(data)
    }

    useEffect(() => {
      fetchData()
    }, [])

    let jsx = driver.map(
     (element, index) => (
       <div key={index} className="drive">
         <p>{element.make} - {element.model}</p>
       </div>
      )
  );

  return (
    <>
      {jsx}
    </>
  );
};


