import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/CreateLog.css';

const CreateLog: React.FC = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    drive_date: '',
    start_time: '',
    start_loc: '',
    driver: '',
    car: '',
    day_night: '',
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    console.log("form: " + JSON.stringify(formData));
    let postData = JSON.stringify(formData);
    console.log("post: " + postData);

    //fetch('http://localhost/reactjs/pwa-server-php/logbook/create.php', {
    fetch('https://pwa.magpiegrove.net/api/logbook/create.php', {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      method: 'POST',
      body: postData,
    })
      .then((response) => {
        navigate(`/`);
      })
      .then((data) => {
        // Handle the response data
        //console.log("data: " + JSON.stringify(data));
      })
      .catch((error) => {
        // Handle any errors
        //console.log("error: " + JSON.stringify(error));
      });
  };

  return (
    <div className="form-div">
    <form onSubmit={handleSubmit}>
      <label>
        Date of Drive:<br/>
        <input type="date" name="drive_date" value={formData.drive_date} onChange={handleInputChange} />
      </label>
      <label>
        Start Time:<br/>
        <input type="text" name="start_time" value={formData.start_time} onChange={handleInputChange} />
      </label><br/>
      <label>
        Location:<br/>
        <input type="text" name="start_loc" value={formData.start_loc} onChange={handleInputChange} />
      </label>
      <label>
        Driver:<br/>
        <input type="text" className="num" name="driver" value={formData.driver} onChange={handleInputChange} />
      </label>
      <label>
        Car:<br/>
        <input type="text" className="num" name="car" value={formData.car} onChange={handleInputChange} />
      </label>
      <label>
        Day / Night:<br/>
        <input type="text" className="num" name="day_night" value={formData.day_night} onChange={handleInputChange} />
      </label>
      <div className="submit-div"> <button type="submit">Submit</button> </div>
    </form>
    </div>
  );
};

export default CreateLog;
