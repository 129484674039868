import React, { useState, useEffect } from 'react';
import { useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import './css/UpdateLog.css';

const UpdateLog: React.FC = () => {

  const { id } = useParams();
  const navigate = useNavigate();

  const [drives, setDrives] = useState<any[]>([])

    /*
    const fetchData = async () => {
      const response = await fetch(`https://pwa.magpiegrove.net/api/logbook/read.php?id=${id}`)
      //const response = await fetch("http://localhost/reactjs/pwa-server-php/logbook/read.php")
      const data = await response.json()
      setDrives(data)
    }
     */

  const fetchData = useCallback(async () => {
    try {
      await fetch(`https://pwa.magpiegrove.net/api/logbook/read.php?id=${id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          setDrives(data)
          //console.log("data: " + JSON.stringify(data));
        })
    } catch (error) {
      //console.log(error.message)
    }
  }, [])

    useEffect(() => {
      fetchData()
    }, [])

    let jsx = drives.map(
     (element, index) => (
       <div key={index} className="current-drive">
         <div>Date:<br/>{element.drive_date}</div>
         <div>Time:<br/>{element.start_time}</div>
         <div>Location:<br/>{element.start_loc}</div>
       </div>
      )
  );

  const [formData, setFormData] = useState({
    end_time: '',
    end_loc: '',
    road: '',
    weather: '',
    traffic: '',
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    console.log("form: " + JSON.stringify(formData));
    let postData = JSON.stringify(formData);
    console.log("post: " + postData);

    //fetch(`http://localhost/reactjs/pwa-server-php/logbook/update.php?id=${id}`, {
    fetch(`https://pwa.magpiegrove.net/api/logbook/update.php?id=${id}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      method: 'POST',
      body: postData,
    })
      .then((response) => {
        navigate(`/`);
      })
      .then((data) => {
        // Handle the response data
        console.log("data: " + data)
      })
      .catch((error) => {
        // Handle any errors
        console.log("error: " + error)
      });
  };

  return (
    <>
    <div className="form-div">
      {jsx}
    <form onSubmit={handleSubmit}>
      <label>
        End Time:<br/>
        <input type="text" name="end_time" value={formData.end_time} onChange={handleInputChange} />
      </label>
      <label>
        End Location:<br/>
        <input type="text" name="end_loc" value={formData.end_loc} onChange={handleInputChange} />
      </label><br/>
      <label>
        Roads:<br/>
        <input type="text" name="road" value={formData.road} onChange={handleInputChange} />
      </label>
      <label>
        Weather:<br/>
        <input type="text" name="weather" value={formData.weather} onChange={handleInputChange} />
      </label>
      <label>
        Traffic:<br/>
        <input type="text" name="traffic" value={formData.traffic} onChange={handleInputChange} />
      </label>
      <div className="submit-div"> <button type="submit">Submit</button> </div>
    </form>
    </div>
  </>
  );
};

export default UpdateLog;
