import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react'

import DriverCard from './DriverCard';
import CarCard from './CarCard';
import './css/ViewDrive.css';

const ViewDrive: React.FC = () => {

  const { id } = useParams();

  const [drives, setDrives] = useState<any[]>([])

  /*
    const fetchData = async () => {
      const response = await fetch(`https://pwa.magpiegrove.net/api/logbook/read.php?id=${id}`)
      //const response = await fetch("http://localhost/reactjs/pwa-server-php/logbook/read.php")
      const data = await response.json()
      setDrives(data)
    }
  */
  const fetchData = useCallback(async () => {
    try {
      await fetch(`https://pwa.magpiegrove.net/api/logbook/read.php?id=${id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          setDrives(data)
          //console.log("data: " + JSON.stringify(data));
        })
    } catch (error) {
      //console.log(error.message)
    }
  }, [])

    useEffect(() => {
      fetchData()
    }, [])

    let jsx = drives.map(
     (element, index) => (
       <div key={index} className="drive">
         <h4>Date of Drive:</h4>
         <h6>{element.drive_date}</h6>
         <p>{element.start_time.substring(0,5)} - {element.end_time.substring(0,5)} <span>({element.duration}min)</span></p>
         <p>{element.start_loc} to {element.end_loc}</p>
         <p><br/></p>
         <div className="condition">{element.road}</div>
         <div className="condition">{element.weather}</div>
         <div className="condition">{element.traffic}</div>
         <p><br/></p>
         <CarCard
            car={element.car}
          />
         <DriverCard
            driverid={element.driver}
          />
       </div>
      )
  );

  return (
    <>
    <div className="drive-card">
      {jsx}
    </div>
  </>
  );
};

export default ViewDrive;
